import React, { useCallback, useEffect, useState } from "react";
import { eventContent, tabsData } from "./constants";
import TabsComponent from "../../components/tabsComponent";
import { cloneCopy } from "../../util";
import PlacementImage from "../../images/PlacementImage.jpg";
//import EventOne from "../../images/social-a1.png";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import { FaSearch, FaRegThumbsUp, FaRegThumbsDown, FaRegCalendar, FaRegClock, } from "react-icons/fa";
import ProgressLineBar from "../../components/progress-bar/ProgressLineBar";
import RatingReview from "../../components/rating-review/rating";
import Comments from "../../components/comments";
import "./workshop.css";
import { Helmet } from "react-helmet";

export default function Workshop() {
  const [startDate, setStartDate] = useState(new Date());
  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tabsData[0].content);
  const [courses, setCourses] = useState();
  const navigate = useNavigate();
  const [eventContentList, setEventContentList] = useState(eventContent);

  const navigateToSocialActivity = () => {
    navigate('/socialActivity')
  }
  useEffect(() => {
    const cloneData = cloneCopy(tabsData);
    setSelectedTab(cloneData[0].content);
    setCourses(cloneData);
  }, []);
  const toggleTab = useCallback((index) => {
    setToggleState(index);
    setSelectedTab(tabsData[index - 1].content);
  }, []);


  const [activeEventKey, setActiveEventKey] = useState(0);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <Helmet>
        {/* Title */}
        <title>Workshops at Java Home Cloud | Elevate Your Skills with Expert-Led Training</title>
        {/* Meta Description */}
        <meta name="description" content="Discover expert-led workshops at Java Home Cloud. Gain practical knowledge in Agile, DevOps, Serverless Development, Resume Preparation, and more. Join our community of learners and elevate your career with interactive, real-world training." />
        {/* Meta Keywords */}
        <meta name="keywords" content="workshops, Agile training, DevOps skills, resume preparation, interactive learning" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.javahome.in/workshop" />
        {/* Structured Data (Schema Markup) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "Workshops at Java Home Cloud",
            "description": "Interactive workshops covering Agile, DevOps, Resume Preparation, Serverless Development, and more. Learn from industry experts and enhance your skills.",
            "eventSchedule": [
              {
                "@type": "Event",
                "name": "Creating Futures Through Technology Conference",
                "startDate": "2024-04-01T10:30",
                "location": {
                  "@type": "Place",
                  "name": "Java Home Cloud",
                  "address": "Online",
                },
              },
              {
                "@type": "Event",
                "name": "Serverless Development",
                "startDate": "2023-12-23T10:30",
                "location": {
                  "@type": "Place",
                  "name": "Java Home Cloud",
                  "address": "Online",
                },
              },
              {
                "@type": "Event",
                "name": "Resume & Interview Preparation Tips and Tricks",
                "startDate": "2023-07-24T10:30",
                "location": {
                  "@type": "Place",
                  "name": "Java Home Cloud",
                  "address": "Online",
                },
              },
            ],
          })}
        </script>
      </Helmet>
      <div className="workshop-page">
        <div className="sub-banner">
          <h1 className="h1-large">
            Elevate Your Knowledge With Our Online Workshops
          </h1>
        </div>
        <div className="workshop-tabs">
          <div className="container">
            <TabsComponent
              courses={courses}
              toggleState={toggleState}
              toggleTab={toggleTab}
            />
            <div className="content-tabs">
              <div className="content active-content">
                {toggleState === 1 && (
                  <div className="row">
                    <div className="col-lg-8">
                      {eventContentList &&
                        eventContentList.map(
                          (
                            {
                              title,
                              subTitle,
                              eventImg,
                              status,
                              date,
                              time,
                              // btnValue,
                            },
                            idx
                          ) => (
                            <div
                              className="event-card"
                              key={idx}

                            >
                              <div className="left_side_event">
                                <img
                                  src={eventImg}
                                  alt="event-img"
                                  loading="lazy"
                                  title="event-img"
                                />
                                <button
                                  className={
                                    "btn " +
                                    (status === "Upcoming"
                                      ? "upcoming"
                                      : "completed")
                                  }
                                >
                                  {status}
                                </button>
                              </div>
                              <div className="right_side_event" key={idx}>
                                <h3>{title}</h3>
                                <span>{subTitle}</span>
                                <div className=" timer  d-flex align-items-center tabs-flex ">
                                  <p className="d-flex align-items-center mb-0 me-4 fw-medium date">
                                    <FaRegCalendar />
                                    <div className="date_time mx-2">{date}</div>
                                  </p>
                                  <p className="d-flex align-items-center mb-0 fw-medium timer">
                                    <FaRegClock />
                                    <div className="date_time mx-2">{time}</div>
                                  </p>
                                  {/* <button className="btn btn-purple ms-auto">
                                  {btnValue}
                                </button> */}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    <div className="col-lg-4">
                      <div className="find_workshop">
                        <h3>Find Workshop</h3>
                        <div className="diff_date_style date_picker">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                          <span>
                            <FaRegCalendar />
                          </span>
                        </div>
                        <div className="form-container keyword_select">
                          <label for="courseName" class="visually-hidden">
                            Select a Course:
                          </label>
                          <select
                            name="courseName"
                            id="courseName"
                            className="form-control form-select"
                          >
                            <option value="Course">Key Words</option>
                            <option value="AWS">AWS</option>
                            <option value="Java">Java</option>
                            <option value="React JS">React JS</option>
                            <option value="Angular JS">Angular JS</option>
                          </select>
                        </div>
                        <div className="input_search">
                          <input
                            className="search-input-box"
                            placeholder="Search"
                            aria-label="Search"
                          />
                          <span>
                            <FaSearch />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {toggleState === 2 && (
                  <div></div>
                )}
                {toggleState === 3 && (
                  <div className="row">
                  </div>
                )}
                {toggleState === 4 && <>{navigateToSocialActivity()}</>}
                {toggleState === 5 && (
                  <>
                    {/* <div className="row progress_rating mx-0">
                    <div className="col-lg-3">
                      <RatingReview />
                    </div>
                    <div className="col-lg-9">
                      <ProgressLineBar />
                    </div>
                  </div>

                  <div className="row comment_container mx-0">
                    <div className="col-lg-12">
                      <Comments />
                    </div>
                  </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
