import React from "react";
import SocialA1 from "../../images/social-a1.jpg";
import SocialA2 from "../../images/social-a2.jpg";
import SocialA3 from "../../images/social-a3.jpg";
import SocialA4 from "../../images/social-a4.jfif";
import { useNavigate } from "react-router-dom";

const activityList = [
  { activity: "What children love about books", image: SocialA1 },
  { activity: "Provide resources and educational materials", image: SocialA2 },
  {
    activity: "Our book donation program is empowering children to develop",
    image: SocialA3,
  },
  { activity: "Learning and reach their full potential", image: SocialA4 },
];
export default function SocialActivities() {
  const navigate = useNavigate();
  const handleSelected = (id) => {
    navigate(`/socialActivity`);
  };
  return (
    <div className="social-activities">
      <div className="container">
        <div className="row">
          <div className="cards-3 ">
            <div className="container">
              <div className="row">
                <div className="col-lg-9">
                  <h2>Get Inspired Today By Social Activities</h2>
                </div>
                <div className="col-lg-3 browse-blc">
                  {/* <a className="btn" href="/#/socialActivity"> */}
                  <a className="btn" onClick={handleSelected}>
                    See more
                  </a>
                </div>
              </div>
            </div>
          </div>
          {activityList &&
            activityList.map(({ activity, image }, idx) => (
              <div className="col-lg-3 col-md-6 mb-md-4" key={idx}>
                <article className="social-a" onClick={() => handleSelected()}>
                  <div className="post-img">
                    <img src={image} alt="social activity" className="img-fluid" loading="lazy" title="social activity"/>
                  </div>
                  <h2 className="social-a-title">{activity}</h2>
                </article>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
