import React, { useState } from "react";
import reviewImage from "../../images/review-img.png";
import BoostyourKnowledge from "../../images/Boost-knowledge.png";
import Heading1Bg from "../../images/h1-bg.svg";
import { FaBorderAll, FaDesktop, FaFilm, FaListUl } from "react-icons/fa";
import { Rating } from "react-simple-star-rating";
import YoutubePlayer from "../home-slider/youtube-player";  // Make sure to import YoutubePlayer

export default function LearnSection() {
  const [show, setShow] = useState(false);  // State for controlling the video popup

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div id="details" className="common-section">
        <div className="container">
          <div className="row center_align_box">
            <div className="col-lg-6 col-xl-6">
              <div className="review-main">
                <div className="review-bg">
                  <h4>420K +</h4>
                  <h6>Satisfied User</h6>
                  <div className="rate">
                    <h6>4.7 / 5</h6>
                    <Rating
                      size={20}
                      fillColor="#15A8F3"
                      transition
                      readonly={true}
                      allowFraction
                      initialValue={4.7}
                    />
                  </div>
                </div>
                <div className="image-container">
                  <img
                    className="img-fluid review-girl"
                    src={reviewImage}
                    alt="review"
                    loading="lazy"
                    title="review"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="text-container">
                <h2>
                  Master new skills with personalized guidance from industry leaders, on your terms.
                </h2>
                <p className="des-txt">
                  9/10 users reported better learning outcomes
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square me-3 icons-bg">
                        <FaDesktop />
                      </div>
                      <h6 className="mb-0">10+ courses</h6>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square me-3 icons-bg">
                        <FaFilm />
                      </div>
                      <h6 className="mb-0">200+ Free Videos</h6>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square me-3 icons-bg">
                        <FaBorderAll />
                      </div>
                      <h6 className="mb-0">500+ Lessons</h6>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex align-items-center mb-3">
                      <div className="flex-shrink-0 btn-square me-3 icons-bg">
                        <FaListUl />
                      </div>
                      <h6 className="mb-0">Qualified Instructors</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="cards-1">
        <div className="container">
          <div className="row center_align_box">
            <div className="col-lg-6 col-xl-7">
              <div className="text-container">
                <h2>
                  <img className="h1-bg " src={Heading1Bg} alt="Heading-bg" loading="lazy" title="heading" />
                  Boost your knowledge <br />
                  and have fun doing it
                </h2>
                <p className="des-txt">
                  Unlimited access to world-class learning from your laptop,
                  tablet, or Phone, join over 15,000+ students.
                </p>
                <a
                  className="btn"
                  href="/courses/aws-solutions-architect-associate/details"
                >
                  See more
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5">
              <div className="image-container course-white-b">
                <img
                  className="img-fluid"
                  src={BoostyourKnowledge}
                  alt="Boost your knowledge"
                  loading="lazy"
                  title="Boost your knowledge"
                />
                <div className="course-box-item">
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="blue-circle" onClick={handleShow}>  {/* Add onClick handler */}
                      <span></span>
                    </div>
                    <div className="course-align-items">
                      <h5 className="text-course">AWS Certified Course</h5>
                      <p className="text-black">753 Students In Classes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cards-2">
        <div className="container">
          <div className="row center_align_box">
            <div className="col-lg-3">
              <h2>
                Most Popular <br />
                Courses
              </h2>
            </div>
            <div className="col-lg-6">
              <p className="des-txt mpc-dec pb-0">
                Unlimited access to world-class learning from your laptop,
                tablet, or Phone, join over 15,000+ students. 753 Students In
                Classes
              </p>
            </div>
            <div className="col-lg-3 browse-blc">
              <a className="btn btn-mpc" href="/courses">
                Browse Courses
              </a>
            </div>
          </div>
        </div>
      </div>
      <YoutubePlayer show={show} handleClose={handleClose} /> {/* Add YoutubePlayer component */}
    </div>
  );
}
