import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FaSearch } from "react-icons/fa";

const CourseCurriculum = ({ courseDetail }) => {
  const [selectedSkill, setSelectedSkill] = useState("");
  const [filteredCurriculum, setFilteredCurriculum] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    // Set the initial selected skill and filtered curriculum
    if (courseDetail.curriculum && courseDetail.curriculum.length > 0) {
      const firstSkillName = courseDetail.curriculum[0].skillName;
      setSelectedSkill(firstSkillName);
      setFilteredCurriculum([courseDetail.curriculum[0]]);
    }
  }, [courseDetail]);

  // Function to handle button click to select skill
  const handleButtonClick = (selectedSkillName) => {
    setSelectedSkill(selectedSkillName);
    const selectedCurriculum = courseDetail.curriculum.find(
      (skill) => skill.skillName === selectedSkillName
    );
    setFilteredCurriculum(selectedCurriculum ? [selectedCurriculum] : []);
    // If search input is present, reapply search filter with the new selected skill
    if (searchInput !== "") {
      searchItems(searchInput);
    }
  };

  // Function to handle search input
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      // Filtering logic when search input is not empty
      const filteredData = courseDetail.curriculum
        .map((item) => {
          const matchingSections = item.curriculum
            .map((section) => {
              const matchingContents = section.contents.filter((content) =>
                content.toLowerCase().includes(searchValue.toLowerCase())
              );
              if (matchingContents.length > 0) {
                return {
                  ...section,
                  contents: matchingContents,
                };
              } else {
                return null;
              }
            })
            .filter(Boolean); // Remove null values (sections without matching content)
          return {
            ...item,
            curriculum: matchingSections,
          };
        })
        .filter((item) => item.curriculum.length > 0); // Remove skills with no matching sections
      setFilteredCurriculum(filteredData);
    } else {
      // Reset to initial state when search input is cleared
      const initialFilteredCurriculum = courseDetail.curriculum.map((skill) => {
        // Only include the curriculum for the initially displayed skill
        if (skill.skillName === selectedSkill) {
          return {
            ...skill,
            curriculum: skill.curriculum.filter(
              (section) => section.contents.length > 0
            ),
          };
        } else {
          // Exclude curriculum for other skills
          return {
            ...skill,
            curriculum: [],
          };
        }
      });
      setFilteredCurriculum(initialFilteredCurriculum);
    }
  };

  // Function to handle expand/collapse all accordion items
  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
    // Update expansion state for each accordion item
    const allItems = document.querySelectorAll(".accordion-collapse");
    allItems.forEach((item) => {
      item.classList.toggle("show", !expandAll); // Update class based on expandAll state
      const icon = item.previousElementSibling.querySelector(
        ".accordion-button .icon"
      );
      if (icon) {
        icon.classList.toggle("expanded", !expandAll); // Update icon class based on expandAll state
      }
    });
  };

  return (
    <div className="row">
      <div className="tab-accordions">
        {filteredCurriculum.length > 0 ? (
          <div className="tab-accord" style={{ marginBottom: "15px" }}>
            <h2>Course Content</h2>
            <div className="search-course">
              <input
                className="search-input-box"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => searchItems(e.target.value)}
              />
              <span>
                <FaSearch />
              </span>
            </div>
          </div>
        ) : null}

        {/* Skill buttons */}
        <div className="p-2 px-0 pt-0 d-flex flex-wrap align-items-center">
          {searchInput.length > 1
            ? filteredCurriculum.map((skill, index) => (
                <div
                  key={index}
                  style={{ marginRight: "10px", marginBottom: "8px" }}
                >
                  <button
                    onClick={() => handleButtonClick(skill.skillName)}
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color:
                        selectedSkill === skill.skillName
                          ? "rgb(21, 168, 243)"
                          : "inherit",
                      border:
                        selectedSkill === skill.skillName
                          ? "2px solid rgb(21, 168, 243)"
                          : "2px solid transparent",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                    className={`btn bg-white rounded-pill mx-0 my-1 px-4 py-2 ${
                      selectedSkill === skill.skillName ? "active" : ""
                    }`}
                  >
                    {skill.skillName}
                  </button>
                </div>
              ))
            : courseDetail.curriculum &&
              courseDetail.curriculum.map((skill, index) => (
                <div
                  key={index}
                  style={{ marginRight: "10px", marginBottom: "8px" }}
                >
                  <button
                    className={`btn bg-white rounded-pill mx-0 my-1 px-4 py-2 ${
                      selectedSkill === skill.skillName ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(skill.skillName)}
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color:
                        selectedSkill === skill.skillName
                          ? "rgb(21, 168, 243)"
                          : "inherit",
                      border:
                        selectedSkill === skill.skillName
                          ? "2px solid rgb(21, 168, 243)"
                          : "2px solid transparent",
                      fontFamily: "'Nunito Sans', sans-serif",
                    }}
                  >
                    {skill.skillName}
                  </button>
                </div>
              ))}
          {/* Button to expand/collapse all accordion items */}
          {filteredCurriculum.length > 0 ? (
            <div className="ms-auto">
              <button className="btn btn-primary" onClick={toggleExpandAll}>
                {expandAll ? "Collapse All" : "Expand All"}
              </button>
            </div>
          ) : null}
        </div>

        {/* Accordion for curriculum */}
        {filteredCurriculum.length > 0 ? (
          <Accordion defaultActiveKey={`0-0`}>
            {filteredCurriculum.map((skill, index) => (
              <React.Fragment key={index}>
                {skill.curriculum.map((section, sectionIndex) => (
                  <Accordion.Item
                    eventKey={`${index}-${sectionIndex}`}
                    key={`${index}-${sectionIndex}`}
                    className="tab-accord"
                  >
                    <Accordion.Header>
                      <h5>
                        {section.sectionNumber}. {section.sectionName}
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      {section.contents.map((content, i) => (
                        <p key={i}>{content}</p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </React.Fragment>
            ))}
          </Accordion>
        ) : (
          <p style={{ color: "gray", textAlign: "center" }}>
            No Data Available
          </p>
        )}
      </div>
    </div>
  );
};

export default CourseCurriculum;
