import React from "react";
import StarChatImg from "../../images/start-chat-img.svg";



export default function PopularTeacher() {
  return (
    <div className="most-popular-teacher">
      <div className="container">
        <div className="most-popular-content">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="image-container">
                <div className="star-review-bg">
                  <img
                    className="img-fluid"
                    src={StarChatImg}
                    alt="start chat"
                    loading="lazy"
                    title="start chat"
                  />
                  <h4>420K +</h4>
                  <h6>Satisfied User</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="text-container">
                <h2>Most Popular Teachers</h2>
                <p className="des-txt">
                  Unlimited access to world-class learning from your laptop,
                  tablet, or Phone, join over 15,000+ students.
                </p>
                <a className="btn" href="/about-us">
                  Get started
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
      
    </div>
  );
}
