import React from "react";

// import TrustedBy from "../../components/trusted-by";
// import TrustedByInPlacement from "../../components/trusted-by/clientblockinplacement";
import TrustedByInPlacement from "./components/clientblockinplacement";
import PlacementDescription from "./components/PlacementDescription";
import RecentlyPlacedStudents from "./components/recentlyPlacedStudents";
import Features from "./components/Features";

import Tata from "../../images/Tata Elxsi.svg";
import Nissan from "../../images/Nissan.svg";
import Infosys from "../../images/Infosys.svg";
import Hcl from "../../images/HCL.svg";
import { Helmet } from "react-helmet";
// import Accenture from "../../images/Accenture.svg";

const trustedData = [
  { title: "TATA", imgSrc: Tata },
  { title: "Nissan", imgSrc: Nissan },
  { title: "Infosys", imgSrc: Infosys },
  { title: "HCL", imgSrc: Hcl },
  // { title: "Accenture", imgSrc: Accenture },
];

export default function Placements() {
  return (
    <>
      <Helmet>
        {/* Title */}
        <title>Our Job Placement Approach | Java Home Cloud</title>
        {/* Meta Description */}
        <meta name="description" content="Java Home Cloud's placement program offers personalized career guidance, resume building, interview coaching, and job matching to help you secure your dream job. Join our trusted network of employers like Tata, Infosys, and HCL." />
        {/* Meta Keywords */}
        <meta name="keywords" content="Java Home Cloud placement program, job placement guidance, career coaching, resume building, Infosys placement, Tata job opportunities" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.javahome.in/placements" />
        {/* Structured Data (Schema Markup) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Java Home",
            "url": "https://www.javahome.in",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.javahome.in/placements{search_term_string}",
              "query-input": "required name=search_term_string",
            },
          })}
        </script>
      </Helmet>
      <div className="placement-page">
        <div className="sub-banner">
          <h1 className="h1-large">Our Placement Approach</h1>
        </div>
        {/* <TrustedBy trustedData={trustedData} /> */}
        <TrustedByInPlacement trustedData={trustedData} />
        <div className="placement-details">
          <div className="container">
            <PlacementDescription />
            <div className="recent-placed-students">
              <RecentlyPlacedStudents />
              <Features />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
