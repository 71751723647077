import Carousel from "react-bootstrap/Carousel";

function HomeCarousel({ carouselData }) {
  return (
    <>
      <Carousel interval={4000}>
        {carouselData &&
          carouselData.map(({ duration, name, src, time, link }, idx) => (
            <Carousel.Item key={idx}>
              <img
                className="img-fluid flex-shrink-0 rounded-circle"
                src={src}
                loading="eager"
                alt="Image"
                title={name}
              />
              <Carousel.Caption>
                <div className="ps-0">
                  <h6 className="mb-1">{name}</h6>
                  <small>{time}</small>
                </div>
              </Carousel.Caption>

              <button className="btn seemore-btn">
                <a href={link}>See more</a>
              </button>
            </Carousel.Item>
          ))}
      </Carousel>
    </>
  );
}

export default HomeCarousel;
