import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../images/logo.svg";
import NavDropdown from "react-bootstrap/NavDropdown";

const navbarList = [
  { name: "Home", route: "/" },
  { name: "Courses", route: "/courses" },
  { name: "Placements", route: "/placements" },
  { name: "Workshop", route: "/workshop" },
  { name: "Internship", route: "/internship" },
  { name: "About Us", route: "/about-us" },
];

const isDevEnvironment = process.env.REACT_APP_ENV === "dev";

if (isDevEnvironment) {
  navbarList.push({ name: "Verify", route: "/verify" });
}

export default function Header() {
  const [scroll, setScroll] = useState(true);
  const location = useLocation();
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (location.pathname === "/" && offset < 30) {
      setScroll(false);
    } else {
      setScroll(true);
    }
  }, [location, offset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const toggle = () => setIsOpen(!isOpen);

  const handleEnquiryClick = () => {
    const navbar = document.querySelector("#navbar");
    const contactSection = document.querySelector("#contact");

    if (navbar && contactSection) {
      let navbarHeight = navbar.offsetHeight;

      if (window.matchMedia("(max-width: 991px)").matches) {
        navbarHeight = 50;
      }

      const contactSectionTop = contactSection.offsetTop;

      const scrollToOffset = contactSectionTop - navbarHeight;

      window.scrollTo({
        top: scrollToOffset,
        behavior: "smooth",
      });
    }
  };
  const isDevelopment = process.env.REACT_APP_ENV === "dev";

  return (
    <nav
      id="navbar"
      className="navbar navbar-expand-lg fixed-top navbar-light top-nav-collapse"
    >
      <div className="container">
        <a className="navbar-brand logo-image" href="/">
          <img src={logo} alt="alternative" loading="lazy" title="logo" />
        </a>
        {isDevelopment && (
          <div className="dev-tag">
            <span
              className="dev-tag-text"
              style={{
                fontWeight: "bold",
                color: "white",
                backgroundColor: "blue",
                borderRadius: "5px",
                fontSize: "13px",
                padding: " 2px 4px 2px 4px",
              }}
            >
              dev
            </span>
          </div>
        )}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggle}
        >
          {!isOpen ? (
            <span className="navbar-toggler-icon"></span>
          ) : (
            <div className="close-icon py-1">✖</div>
          )}
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto navbar-nav-scroll">
            {navbarList &&
              navbarList.map(({ name, route }, idx) => (
                <li className="nav-item" key={idx}>
                  <NavLink to={route} className="nav-link">
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                      onClick={toggle}
                    >
                      {name}
                    </span>
                  </NavLink>
                </li>
              ))}
          </ul>
          <span className="nav-item">
            <a className="btn" onClick={handleEnquiryClick}>
              <span
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
                onClick={toggle}
              >
                Enquiry Now
              </span>
            </a>
          </span>
        </div>
      </div>
    </nav>
  );
}
