import React, { useEffect, useState } from "react";

export default function CoursesList({
  handleSelected,
  data,
  tabTitle,
  searchQuery,
}) {
  let allCourses = data.courses;
  const [selectedTabItem, setSelectedTabItem] = useState([]);
  function truncateString(str, n) {
    if (str?.length > n) {
      return str.substring(0, n) + "...";
    } else {
      return str;
    }
  }
  truncateString("courseDescription", 11);

  useEffect(() => {
    if (tabTitle.length === 0) {
      setSelectedTabItem(data.courses);
    } else {
      setSelectedTabItem(tabTitle);
    }
  }, [data, tabTitle]);


  const filteredCourses = allCourses?.filter(
    (allCourses) =>
      allCourses.category?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      allCourses.courseName?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <div className="content active-content">
      <div className="row">
        {searchQuery?.length > 1
          ? filteredCourses.map(
              ({ photo, courseId, category, courseName, summary }, idx) => (
                <div className="col-lg-3 pe-0 col-md-6" key={idx}>
                  <div
                    className="tab-item tab-first-item"
                    onClick={() => handleSelected(courseId)}
                  >
                    <div className="tabs-class-heading1">
                      <h6 className="tab-course-name-H">{category}</h6>
                    </div>
                    <div className="tab-content-image">
                      <img
                        className="img-fluid"
                        src={photo}
                        alt="Tab first image"
                        loading="lazy"
                        title="course image"
                      />
                    </div>
                    <div className="tab-content-des">
                      <h6>{courseName} </h6>
                      <p>{`${truncateString(summary, 60)}`}</p>
                    </div>
                  </div>
                </div>
              )
            )
          : selectedTabItem &&
            selectedTabItem.map(
              ({ photo, courseId, category, courseName, summary }, idx) => (
                <div className="col-lg-3 pe-0 col-md-6" key={idx}>
                  <div
                    className="tab-item tab-first-item"
                    onClick={() => handleSelected(courseId)}
                  >
                    <div className="tabs-class-heading1">
                      <h6 className="tab-course-name-H">{category}</h6>
                    </div>
                    <div className="tab-content-image">
                      <img
                        className="img-fluid"
                        src={photo}
                        alt="Tab first image"
                        loading="lazy"
                        title="course image"
                      />
                    </div>
                    <div className="tab-content-des">
                      <h6>{courseName} </h6>
                      <p>{`${truncateString(summary, 60)}`}</p>
                    </div>
                  </div>
                </div>
              )
            )}
      </div>
    </div>
  );
}
