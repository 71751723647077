import React from "react";
import ClassIcon1 from "../../images/offline.svg";
import ClassIcon2 from "../../images/College.svg";
import ClassIcon3 from "../../images/workshop_1.svg";
import ClassIcon4 from "../../images/online.svg";
import { Link } from "react-router-dom";

const servicesList = [
  {
    path: "courses",
    image: ClassIcon1,
    name: "Offline Classes",
    desc: "Offline classes provide interactive learning with face-to-face interaction, and extracurricular activities for holistic development.",
  },
  {
    path: "collageTraining",
    image: ClassIcon2,
    name: "College Training",
    desc: "Empowering college students with cutting-edge IT skills for a tech-driven future.",
  },
  {
    path: "workshop",
    image: ClassIcon3,
    name: "Workshops",
    desc: "Unlock your potential with hands-on learning in our dynamic workshops.",
  },
  {
    path: "courses",
    image: ClassIcon4,
    name: "Online Classes",
    desc: "Learn anytime, anywhere, at your own pace, with limitless opportunities for personal and professional development.",
  },
];

export default function SpecializedServices() {
  return (
    <>
      <div className="specialized-services-main">
        <div className="specialized-services">
          <div className="container">
            <div className="text-container">
              <h2 className="text-container-heading">Specialized Services</h2>
              <p className="des-txt">
                Unlimited access to world-class learning from your laptop, tablet,{" "}
                <br />
                or Phone, join over 15,000+ students.
              </p>
            </div>
            <div className="spec-box">
              <div className="row spec-position">
                {servicesList &&
                  servicesList.map(({ image, name, desc, path }, idx) => (
                    <div className="col-lg-3 col-md-6" key={idx}>
                      <div className="service-card">
                        <Link to={path}>
                          <div className="service-card-body">
                            <img
                              className="img-fluid"
                              src={image}
                              alt="start chat"
                              loading="lazy"
                              title={name}
                            />
                            <h3 className="class-h">{name}</h3>
                            <p className="lead mb-0">{desc}</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="review-carousel-main">
          <div className="cards-3 p-0">
            <div className="container row center_align_box m-auto">
              <div className="col-lg-8 ps-0">
                <h2>
                  Over a thousand students love and
                  <br /> recommend our courses
                </h2>
              </div>
              <div className="col-lg-4 browse-blc pe-0">
                <a className="btn" href="/placements">
                  Know More About Placement
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
